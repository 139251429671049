<template>
  <component
    :is="tag"
    v-if="activity && activity.id"
    :disabled="disabled"
    :block="block"
    :theme="theme"
    :loading="pending"
    @click.prevent="handleClick"
    ><slot>Перейти к заданию</slot></component
  >
</template>

<script>
import { getStepikCourseURL } from "@/utils/profile";
import YandexModal from "@/modals/YandexModal";
import { clientApiRequest } from "@/services/api";

export default {
  name: "EnrollButton",
  props: {
    activity: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
    tag: {
      type: String,
      default: "BaseButton",
    },
    theme: {
      type: String,
      default: "primary",
    },
    // eslint-disable-next-line vue/no-unused-properties
    isEnrolled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
    },
    attemptId: {
      type: Number,
    },
  },
  data() {
    return {
      pending: false,
    };
  },
  computed: {
    requireYandex() {
      return this.$store.getters["user/requireYandex"];
    },
    isYandex() {
      return !!this.activity?.yacontest;
      // return Boolean(activity.stepik_course_id)  ;
    },
    url() {
      const { activity } = this;
      if (!activity) return;
      // если активность на степике
      if (activity.stepik_course_id) {
        return getStepikCourseURL(activity.stepik_course_id);
      }
      return activity.external_url;
    },
  },
  methods: {
    openURL(url) {
      if (url && typeof url === "string") {
        window.location.href = url;
      } else {
        this.showErrorModal({
          content: "Не удалось зарегистрироваться на курс",
          message: "Некорректное значение URL",
        });
      }
    },
    handleClick() {
      const { url, isYandex, disabled, pending } = this;
      if (!url || disabled || pending) return;
      // Если есть баллы или это внешняя активность,
      // то сразу кидаем на активность
      if (!isYandex) {
        this.openURL(url);
        return;
      }
      // Если активность на степике,
      // но у пользователя не привязан степик аккаунт
      if (this.requireYandex) {
        this.$modal.show(
          YandexModal,
          {},
          {
            adaptive: true,
            height: "auto",
          },
          {
            closed: this.onYandexModalClose,
          }
        );
        return;
      }
      this.enrollRequest();
    },
    async enrollRequest() {
      const { attemptId } = this;
      try {
        this.pending = true;

        const { data } = await clientApiRequest({
          method: "POST",
          baseURL: "/yacontest",
          url: `/attempt/${attemptId}/participate`,
        });
        window.open(data, "_contest_course");
        this.pending = false;
      } catch (error) {
        // игнорируем ошибку о том,
        // что пользователь уже зарегистрирован на курс
        this.pending = false;
        // if (
        //   error.message ===
        //   "Member с такими значениями полей Group и User уже существует."
        // ) {
        //   this.openURL(url);
        //   return;
        // }
        this.showErrorModal({
          content: "Не удалось зарегистрироваться на курс",
          message: error.message,
        });
      }
    },
    onYandexModalClose() {
      if (!this.requireYandex) {
        this.handleClick();
      }
    },
  },
};
</script>

<style></style>
